import { useState } from 'react';
import {Button, InputGroup, InputGroupText, Input, Table, Spinner} from 'reactstrap';

function App() {
  const [loading, setLoading] = useState(false);
  const [creatingPlaylist, setCreatingPlaylist] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [targetDemographic, setTargetDemographic] = useState('');
  const [playlistTitle, setPlaylistTitle] = useState('');
  const [mood, setMood] = useState('');
  const [moodWeight, setMoodWeight] = useState('1');
  const [vocals, setVocals] = useState('');
  const [vocalsWeight, setVocalsWeight] = useState('1');
  const [energy, setEnergy] = useState('');
  const [energyWeight, setEnergyWeight] = useState('1');
  const [arousal, setArousal] = useState('');
  const [arousalWeight, setArousalWeight] = useState('1');
  const [genre, setGenre] = useState('');
  const [genreWeight, setGenreWeight] = useState('1');
  const [texture, setTexture] = useState('');
  const [textureWeight, setTextureWeight] = useState('1');
  const [valence, setValence] = useState('');
  const [valenceWeight, setValenceWeight] = useState('1');
  const [audienceAges, setAudienceAges] = useState('');
  const [audienceAgesWeight, setAudienceAgesWeight] = useState('1');
  const [audienceRegions, setAudienceRegions] = useState('');
  const [audienceRegionsWeight, setAudienceRegionsWeight] = useState('5');
  const [originRegion, setOriginRegion] = useState('');
  const [originRegionWeight, setOriginRegionWeight] = useState('1');
  const [originDecade, setOriginDecade] = useState('');
  const [originDecadeWeight, setOriginDecadeWeight] = useState('5');
  const [minimumBpm, setMininumBpm] = useState('');
  const [minimumBpmWeight, setMinimumBpmWeight] = useState('1');
  const [maximumBpm, setMaximumBpm] = useState('');
  const [maximumBpmWeight, setMaximumBpmWeight] = useState('1');
  const [minimumDuration, setMininumDuration] = useState('');
  const [minimumDurationWeight, setMinimumDurationWeight] = useState('1');
  const [maximumDuration, setMaximumDuration] = useState('');
  const [maximumDurationWeight, setMaximumDurationWeight] = useState('1');
  const [tracks, setTracks] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [accessToken, setAccessToken] = useState('');
  const [invalidPassword, setInvalidPassword] = useState('');
  async function searchTracks() {
    setLoading(true);
    setTracks([]);
    const searchString = `${process.env.REACT_APP_CURATION_CATALOGUER_URL}/tracks/search?preview=true&name=${name}&moods=${mood}&moodsWeight=${moodWeight}&energy=${energy}&energyWeight=${energyWeight}&vocals=${vocals}&vocalsWeight=${vocalsWeight}&arousal=${arousal}&arousalWeight=${arousalWeight}&texture=${texture}&textureWeight=${textureWeight}&valence=${valence}&valenceWeight=${valenceWeight}&genre=${genre}&genreWeight=${genreWeight}&minimum_bpm=${minimumBpm}&minimum_bpmWeight=${minimumBpmWeight}&maximum_bpm=${maximumBpm}&maximum_bpmWeight=${maximumBpmWeight}&minimum_duration=${minimumDuration}&minimum_durationWeight=${minimumDurationWeight}&maximum_duration=${maximumDuration}&maximum_durationWeight=${maximumDurationWeight}&audience_ages=${audienceAges}&audience_agesWeight=${audienceAgesWeight}&audience_regions=${audienceRegions}&audience_regionsWeight=${audienceRegionsWeight}&origin_region=${originRegion}&origin_regionWeight=${originRegionWeight}&origin_decade=${originDecade}&origin_decadeWeight=${originDecadeWeight}`;
    let response;
    try {
      response = await fetch(searchString, {
        headers: {
          Authorization: 'Bearer ' + accessToken
        }
      });      
    } catch (error) {
      console.log('error performing search.');
      setLoading(false);
      setTracks([]);
    }

    if (response?.ok) {
      const responseTracks = await response.json();
      setTracks(responseTracks.results);
      setTotalItems(responseTracks.total_items);
    }
    setLoading(false);
  }

  async function login() {
    let response;

    var params = {
      'email': email,
      'password': password
    }

    try
    {
      const loginUrl = `${process.env.REACT_APP_AUTH_URL}/login`
      response = await fetch(loginUrl, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
      });
  
    } catch (error) {
      console.log('error logging in!!!');
    }

    if (response?.ok) {
      const responsel = await response.json();

      setAccessToken(responsel.tokens.access);

    }
    else
    {
      setInvalidPassword("Invalid username or password.  Please try again.");
    }
  }

  async function createPlaylist() {

    setCreatingPlaylist(true);
    const searchString = `${process.env.REACT_APP_CURATION_CATALOGUER_URL}/tracks/search?preview=false&name=${name}&moods=${mood}&moodsWeight=${moodWeight}&energy=${energy}&energyWeight=${energyWeight}&vocals=${vocals}&vocalsWeight=${vocalsWeight}&arousal=${arousal}&arousalWeight=${arousalWeight}&texture=${texture}&textureWeight=${textureWeight}&valence=${valence}&valenceWeight=${valenceWeight}&genre=${genre}&genreWeight=${genreWeight}&minimum_bpm=${minimumBpm}&minimum_bpmWeight=${minimumBpmWeight}&maximum_bpm=${maximumBpm}&maximum_bpmWeight=${maximumBpmWeight}&minimum_duration=${minimumDuration}&minimum_durationWeight=${minimumDurationWeight}&maximum_duration=${maximumDuration}&maximum_durationWeight=${maximumDurationWeight}&audience_ages=${audienceAges}&audience_agesWeight=${audienceAgesWeight}&audience_regions=${audienceRegions}&audience_regionsWeight=${audienceRegionsWeight}&origin_region=${originRegion}&origin_regionWeight=${originRegionWeight}&origin_decade=${originDecade}&origin_decadeWeight=${originDecadeWeight}`;
    let response1;
    try {
      response1 = await fetch(searchString, {
        headers: {
          Authorization: 'Bearer ' + accessToken
        }
      });      
    } catch (error) {
      console.log('error performing full search for create playlist.');
      setCreatingPlaylist(false);
      setTracks([]);
    }

    if (response1?.ok) {
      const responseTracks = await response1.json();

      var playlist_tracks = responseTracks.results.map(t => t.seven_digital_id.toString());
      
      var params = {
        'name': playlistTitle,
        'targetDemographic': targetDemographic,
        'tracks': playlist_tracks,
        'playlist_properties': {
          "status": "draft",
          "description": ""
        }
      }

      let response;
      try
      {
        const createString = `${process.env.REACT_APP_CURATION_CATALOGUER_URL}/playlists`;

        response = await fetch(createString, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + accessToken
          },
          body: JSON.stringify(params)
        });
    
      } catch (error) {
        console.log('error creating playlist.');
        setCreatingPlaylist(false);
      }

      if (response?.ok) {
        var playlist_uuid = await response.text();
      }
    }  

    setCreatingPlaylist(false);
  }

  return (
    <div className="container">
        <div className='row mt-3 mb-3'>
          <h1>Create 7Digital Playlist</h1>
        </div>

        {accessToken === "" ? (
        <div>
          <div className="row mb-3">
          <div className='col'>
            <h2>Login</h2>
            <div style={{color: 'red'}}>{invalidPassword}</div>
          </div>
        </div>
        <div  className="row mb-3">
          <div className="col-10">
              <InputGroup>
                <Input placeholder='Email' value={email} onChange={e => setEmail(e.target.value)}/>
              </InputGroup>
          </div>
        </div>
        <div  className="row mb-3">
          <div className="col-10">
              <InputGroup>
                <Input placeholder='Password' type='password' value={password} onChange={e => setPassword(e.target.value)}/>
              </InputGroup>
          </div>
        </div>
        <div className="row mb-5">
          <div className="column">
            <Button color="primary" onClick={login}>Login</Button>
          </div>
        </div>
        </div>
    ) : (

        <div>
        <div className="row mb-3">
          <div className="col-10">
            <InputGroup>
              <Input placeholder='Playlist name' value={playlistTitle} onChange={e => setPlaylistTitle(e.target.value)}/>
            </InputGroup>
          </div>
        </div>
        <div className='row mb-3'>
          <div className="col-6">
            <InputGroup>
              <Input placeholder='Target demographic' value={targetDemographic} onChange={e => setTargetDemographic(e.target.value)}/>
            </InputGroup>
          </div>
        </div>
        <div className="row mb-3">
          <div className='col'>
            <h2>Search Tracks</h2>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-6">
            <InputGroup>
              <Input placeholder='Track name...' value={name} onChange={e => setName(e.target.value)}/>
            </InputGroup>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-3">
          <InputGroup>
            <InputGroupText>
            Mood:
            </InputGroupText>
            <select value={mood} onChange={e => setMood(e.target.value)} >
              <option value="">-- select an option -- </option>
              <option value="aggressive">Aggressive</option>
              <option value="amusing">Amusing</option>
              <option value="anxious">Anxious</option>
              <option value="calm">Calm</option>
              <option value="devotional">Devotional</option>
              <option value="dreamy">Dreamy</option>
              <option value="energetic">Energetic</option>
              <option value="enigmatic">Enigmatic</option>
              <option value="epic">Epic</option>
              <option value="erotic">Erotic</option>
              <option value="positive">Positive</option>
              <option value="sad">Sad</option>
              <option value="scary">Scary</option>
              <option value="wild">Wild</option>
            </select>
            <select value={moodWeight} onChange={e => setMoodWeight(e.target.value)} >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>
          </InputGroup>  
          </div>
          <div className="col-3">
          <InputGroup>   
          <InputGroupText>
            Vocals:
          </InputGroupText>
            <select value={vocals} onChange={e => setVocals(e.target.value)} >
              <option value="">-- select an option -- </option>
              <option value="female vocals">Female vocals</option>
              <option value="male vocals">Male vocals</option>
              <option value="mixed vocals">Mixed vocals</option>
              <option value="instrumental">Instrumental</option>
            </select>
            <select value={vocalsWeight} onChange={e => setVocalsWeight(e.target.value)} >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>
          </InputGroup>
          </div>
          <div className="col-3">
          <InputGroup>   
          <InputGroupText>
            Energy:
          </InputGroupText>
            <select value={energy} onChange={e => setEnergy(e.target.value)} >
              <option value="">-- select an option -- </option>
              <option value="very quiet">Very quiet</option>
              <option value="quiet">Quiet</option>
              <option value="moderate">Moderate</option>
              <option value="loud">Loud</option>
              <option value="very loud">Very Loud</option>
            </select>
            <select value={energyWeight} onChange={e => setEnergyWeight(e.target.value)} >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>
          </InputGroup>
          </div>
          <div className="col-3">
          <InputGroup>   
          <InputGroupText>
            Arousal:
          </InputGroupText>
            <select value={arousal} onChange={e => setArousal(e.target.value)} >
              <option value="">-- select an option -- </option>
              <option value="very calm">Very calm</option>
              <option value="calm">Calm</option>
              <option value="moderate arousal">Moderate Arousal</option>
              <option value="energetic">Energetic</option>
              <option value="very energetic">Very energetic</option>
            </select>
            <select value={arousalWeight} onChange={e => setArousalWeight(e.target.value)} >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>

          </InputGroup>
          </div>
        </div>  
        <div className="row mb-3">
          <div className="col-3">
          <InputGroup>
            <InputGroupText>
            Genre:
            </InputGroupText>
            <select value={genre} onChange={e => setGenre(e.target.value)} >
              <option value="">-- select an option -- </option>
              <option value="Alternative">Alternative</option>
              <option value="Blues">Blues</option>
              <option value="Classical">Classical</option>
              <option value="Country">Country</option>
              <option value="Electronic">Electronic</option>
              <option value="Folk">Folk</option>
              <option value="Hip Hop">Hip Hop</option>
              <option value="Jazz">Jazz</option>
              <option value="Latin">Latin</option>
              <option value="Metal">Metal</option>
              <option value="Pop">Pop</option>
              <option value="R&B">R&B</option>
              <option value="Reggae">Reggae</option>
              <option value="Rock">Rock</option>
              <option value="Spoken Word">Spoken Word</option>
              <option value="World">World</option>
            </select>
            <select value={genreWeight} onChange={e => setGenreWeight(e.target.value)} >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>

          </InputGroup>  
          </div>
          <div className="col-3">
          <InputGroup>   
          <InputGroupText>
            Texture:
          </InputGroupText>
            <select value={texture} onChange={e => setTexture(e.target.value)} >
              <option value="">-- select an option -- </option>
              <option value="very thin">Very thin</option>
              <option value="thin">Thin</option>
              <option value="moderate texture">Moderate texture</option>
              <option value="full">Full</option>
              <option value="very full">Very full</option>
            </select>
            <select value={textureWeight} onChange={e => setTextureWeight(e.target.value)} >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>

          </InputGroup>
          </div>
          <div className="col-3">
          <InputGroup>   
          <InputGroupText>
            Valence:
          </InputGroupText>
            <select value={valence} onChange={e => setValence(e.target.value)} >
              <option value="">-- select an option -- </option>
              <option value="very sad">Very sad</option>
              <option value="Sad">Sad</option>
              <option value="moderate valence">Moderate valence</option>
              <option value="positive">Positive</option>
              <option value="Very positive">Very positive</option>
            </select>
            <select value={valenceWeight} onChange={e => setValenceWeight(e.target.value)} >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>
          </InputGroup>

          </div>
        </div>    
        <div className="row mb-3">
          <div className="col-6">
          <InputGroup>
            <InputGroupText>
            Audience Age:
            </InputGroupText>
            <select value={audienceAges} onChange={e => setAudienceAges(e.target.value)} >
              <option value="">-- select an option -- </option>
              <option value="Generation X">Generation X</option>
              <option value="Generation Z">Generation Z</option>
              <option value="Older Generation B">Older Generation B</option>
              <option value="Older Generation Y">Older Generation Y</option>
              <option value="Younger Generation B">Younger Generation B</option>
              <option value="Younger Generation Y">Younger Generation Y</option>
            </select>
            <select value={audienceAgesWeight} onChange={e => setAudienceAgesWeight(e.target.value)} >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>
          </InputGroup>  
          </div>
          <div className="col-6">
          <InputGroup>   
          <InputGroupText>
            Audience Regions:
          </InputGroupText>
            <select value={audienceRegions} onChange={e => setAudienceRegions(e.target.value)} >
              <option value="">-- select an option -- </option>
              <option value="Australia and New Zealand">Australia and New Zealand</option>
              <option value="Central America and the Carribean">Central America and the Carribean</option>
              <option value="Central and Southern Asia">Central and Southern Asia</option>
              <option value="Eastern Asia">Eastern Asia</option>
              <option value="Eastern Europe">Eastern Europe</option>
              <option value="Northern Africa and Western Asia">Northern Africa and Western Asia</option>
              <option value="Northern America">Northern America</option>
              <option value="South America">South America</option>
              <option value="South-Eastern Asia">South-Eastern Asia</option>
              <option value="Southern Europe">Southern Europe</option>
              <option value="Sub-Saharan Africa">Sub-Saharan Africa</option>
              <option value="Western and Northern Europe">Western and Northern Europe</option>
              </select>
              <select value={audienceRegionsWeight} onChange={e => setAudienceRegionsWeight(e.target.value)} >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>

          </InputGroup>
          </div>
        </div> 
        <div className="row mb-3">
          <div className="col-6">
          <InputGroup>
            <InputGroupText>
            Origin Decade:
            </InputGroupText>
            <select value={originDecade} onChange={e => setOriginDecade(e.target.value)} >
              <option value="">-- select an option -- </option>
              <option value="pre-1950s">pre-1950s</option>
              <option value="1950s">1950s</option>
              <option value="1960s">1960s</option>
              <option value="1970s">1970s</option>
              <option value="1980s">1980s</option>
              <option value="1990s">1990s</option>
              <option value="2000s">2000s</option>
              <option value="2010s">2010s</option>
              <option value="2020s">2020s</option>
            </select>
            <select value={originDecadeWeight} onChange={e => setOriginDecadeWeight(e.target.value)} >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>

          </InputGroup>  
          </div>
          <div className="col-6">
          <InputGroup>   
          <InputGroupText>
            Origin Region:
          </InputGroupText>
            <select value={originRegion} onChange={e => setOriginRegion(e.target.value)} >
              <option value="">-- select an option -- </option>
              <option value="Central America and the Carribean">Central America and the Carribean</option>
              <option value="Central and Southern Asia">Central and Southern Asia</option>
              <option value="Eastern Asia">Eastern Asia</option>
              <option value="Eastern Europe">Eastern Europe</option>
              <option value="Northern Africa and Western Asia">Northern Africa and Western Asia</option>
              <option value="Northern America">Northern America</option>
              <option value="Oceania">Oceania</option>
              <option value="South America">South America</option>
              <option value="South-Eastern Asia">South-Eastern Asia</option>
              <option value="Southern Europe">Southern Europe</option>
              <option value="Sub-Saharan Africa">Sub-Saharan Africa</option>
              <option value="Western and Northern Europe">Western and Northern Europe</option>
            </select>
            <select value={originRegionWeight} onChange={e => setOriginRegionWeight(e.target.value)} >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>

          </InputGroup>
          </div>
        </div>    

        <div className="row mb-3">
          <div className="col-4">
          <InputGroup>
            <InputGroupText>
            Minimum BPM:
            </InputGroupText>
            <Input placeholder='Minimum BPM' value={minimumBpm} onChange={e => setMininumBpm(e.target.value)}/>
          </InputGroup>  
          </div>
          <div className="col-4">
          <InputGroup>   
          <InputGroupText>
            Maximum BPM:
          </InputGroupText>
          <Input placeholder='Maximum BPM' value={maximumBpm} onChange={e => setMaximumBpm(e.target.value)}/>
          </InputGroup>
          </div>
        </div>    
        <div className="row mb-3">
          <div className="col-4">
          <InputGroup>
            <InputGroupText>
            Minimum Duration:
            </InputGroupText>
            <Input placeholder='Minimum Duration' value={minimumDuration} onChange={e => setMininumDuration(e.target.value)}/>
          </InputGroup>  
          </div>
          <div className="col-4">
          <InputGroup>   
          <InputGroupText>
            Maximum Duration:
          </InputGroupText>
          <Input placeholder='Maximum Duration' value={maximumDuration} onChange={e => setMaximumDuration(e.target.value)}/>
          </InputGroup>
          </div>
        </div>    

      <div className="row mb-5">
        <div className="column">
          <Button color="secondary" onClick={searchTracks}>Search Tracks</Button>
        </div>
      </div>
      {loading ? (
        <Spinner color="primary" style={{
          height: '3rem',
          width: '3rem'
        }}>
        Loading...
        </Spinner>
      ) : (
        tracks.length === 0 ? <div>Enter your search...</div> : (
          <>
          <div>Total results: {totalItems}</div>
          <div>(Showing first 50 results)</div>
          <Table>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>Name</th>
              <th>Artist</th>
              <th>Score</th>
              <th>Origin Region</th>
              <th>Origin Decade</th>
              <th>Audience Region</th>
              <th>Audience Age</th>
            </tr>
          </thead>
          <tbody>
          {tracks.map((track, index) => (

            index < 100 && (
              <tr key={track.id}>
              <td>{track.seven_digital_id}</td>
              <td>{track.title}</td>
              <td>{
                /*track.artists[0].name*/ 
                track.artists.map(artist => artist.name).join(', ')
                }
              </td>
              <td>
                <strong>Total:</strong> {track.sort_score}<br/> 
                {track.scores.map((score, index) => {
                  const [key, value] = Object.entries(score)[0];
                  return (
                    <div key={index}> 
                      {key} : {value}<br/>
                    </div>
                  );
                })} 
              </td>
              <td>{track.origin_region}</td>
              <td>{track.origin_decade}</td>
              <td>{track.audience_region}<br/>{track.secondary_audience_region}<br/>{track.tertiary_audience_region}</td>
              <td>{track.audience_age}<br/>{track.secondary_audience_age}<br/>{track.tertiary_audience_age}</td>
            </tr>

            )
          ))}  

          </tbody>
        </Table>

        </>  
        )
      )}
        <div className="row mb-5 mt-5">
          <div className="col-6">
            <InputGroup>
              <Button color="primary" size="lg" disabled={tracks.length === 0 || creatingPlaylist === true} onClick={createPlaylist}>Create Playlist</Button>
            </InputGroup>
          </div>
        </div>
        <div className="row">
          <div className="col">
          {creatingPlaylist ? (
            <Spinner color="danger" style={{
            height: '3rem',
            width: '3rem'
            }}>
            Loading...
            </Spinner> ) : (
              <div></div>
            )}
          </div>
        </div>

    </div>
          )}

    </div>
  );
}

export default App;
